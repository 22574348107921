.payment-clicked {
    background-color: #59c19f !important;
}
.payment-logo {
    background-color: white !important;
}
.payment-exist {
    background-color: grey !important;
}

.cancel-button {
    border-radius: 5px;
    width: 90%;

    background-color: grey !important;
}

.proceed-button {
    border-radius: 5px;
    width: 90%;
    /* background-color: #ffd166 !important; */
    /* color: black !important; */
}

.edit-selected-bank {
    width: 95%;
    border-radius: 5px;
    /* height: 40px !important; */
    border: 1px black solid;
}

@media only screen and (max-width: 960px) {
    .edit-selected-bank {
        width: 95% !important;
        height: 40px !important;
    }
}
