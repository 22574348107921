.addon-container {
    /* background: #f1f1f1; */
    /* min-height: calc(100vh - 80px); */
}

.addon-form-container {
    background: white;
    /* min-height: calc(100vh - 80px); */
    box-shadow: 0px 0px 5px 1px rgb(218, 218, 218);
}

.center-image-preview-container {
    position: relative;
    background-color: #c4c4c4;
    height: 180px;
    border-radius: 10px;
    margin-left: 80px;
    margin-top: 40px;
}
.center-image-preview {
    position: absolute;
    height: 180px;
    left: 18%;
    font-size: 18px;
}
.rti--container {
    margin-top: 0.5em;
    border-color: black !important;
    min-height: 15vh;
    align-items: baseline !important;
}

.rti--tag {
    color: white !important;
    background-color: #3395ff !important;
    padding: 0.3em !important;
}

.react-tagsinput {
    border: 1px solid black !important;
    border-radius: 6px !important;
    margin-top: 5px;
}
.react-tagsinput-tag {
    background-color: #3395ff !important;
    color: white !important;
    border-radius: 6px !important;
}

@media only screen and (max-width: 960px) {
    .mobile-view {
        margin-top: 40px !important;
    }
}
