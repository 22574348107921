.not-found-title {
    font-size: 2.5rem;
    font-weight: 700;
    font-family: 'Orbitron';
    color: #A254FC;
}

.not-found-desc {
    width: 500px;
    font-size: .8rem;
    text-align: center;
    color: #A254FC;
    margin: 0 auto;
}