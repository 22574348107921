.App {
    width: 100%;
    text-align: center;
    background-color: #f5f5f5 !important;
    overflow-x: hidden;
}

.plan-detail {
    width: 100%;
    height: 100vh;
    text-align: center;
    padding-top: 60px;
    background-color: rgb(245, 245, 245) !important;
}

p {
    padding-bottom: 0;
    margin-bottom: 0 !important;
}
div {
    font-family: "Mulish", sans-serif !important;
}

.razorpay-logo {
    background-color: #0c2651;
}
#page-wrap {
    padding-bottom: 10px;
    margin-left: 16em;
    transition: all 0.3s ease-in-out;
}
#page-unwrap {
    padding-bottom: 10px;
    margin-left: 0;
    transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 991px) {
    .App {
        overflow-x: hidden;
    }

    #page-wrap {
        margin-left: 0;
    }
}

/* width */
::-webkit-scrollbar {
    width: 3px;
    background-color: #f5f5f5;
}
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
