.card-layout {
    padding: 1em 1.5em 1em 1em;
}

.card-design {
    border-radius: 6px;
}
.card-design:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
        rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
        rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.card-image-design {
    border-radius: 6px 6px 0px 0px;
}

.swal2-icon.swal2-warning {
    /* background-color: red !important; */
    color: red;
    border-color: red;
}

.swal2-icon.swal2-success {
    background-color: #0c2651 !important;
    color: white !important;
    border-color: white !important;
}

.hover-card {
    cursor: pointer;
}

.swal2-header,
.swal2-content,
swal2-actions {
    padding: 0;
}

.swal2-cancel {
    background-color: white;

    color: #0c2651 !important;

    /* border-color: #0c2651 !important;
    border-style: solid !important;
    border-width: 1px !important; */
    border: 1px solid #0c2651 !important;
}

@media only screen and (max-width: 960px) {
    .card-layout {
        padding: 1em 0.5em;
    }
}
