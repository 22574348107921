input[type="text"]:disabled{
    background-color:#C4C4C4;    
    border:none;
}

input[type="text"]{
    padding:10px;
}

.btn-toggle1,.btn-toggle2{
    background-color: #F5F5F5 !important;
    color:black !important;
    font-weight: 600 !important;
    margin-left: 0 !important;
    border-radius: 10px !important;
    font-size:1.2rem !important;
}

.btn-toggle-active1, .btn-toggle-active2{
    background-color: rgba(12, 38, 81, 0.25) !important;
    border-radius: 10px !important;
    font-weight: 600 !important;
    color:black !important;
}

.btn-toggle-active1:focus, .btn-toggle-active2:focus{
    color:black !important;
}


.paymentImg1{
    width:13%;
    height:auto;
}

.paymentImg2{
    width:25%;
    height:auto;
}

.btn-edit{
    color:#F5F5F5 !important;
    background-color: #0C2651 !important;
    text-transform:capitalize !important;
    font-size: 16px !important;
    margin-left: -10px !important;
}

.disable{
    cursor: not-allowed !important;
}

h2{
    font-weight: 600 !important;
    font-size: 1.3rem !important;
}

h4{
    font-weight: 600 !important;
    font-size: 1.2rem !important;
}

h3{
    font-size: 1.2rem !important;
}

.col-align{
    align-items: center;
}

.btn-group{
    display: flex !important;
    column-gap: 35px !important;
}

@media only screen and (max-width: 767px){

    .paymentImg1{
        width:15%;
        height:auto;
    }
    
    .paymentImg2{
        width:25%;
        height:auto;
    }

    .btn-toggle1,.btn-toggle2{
        font-size: 0.9rem !important;
    }

    .btn-edit{
        font-size: 0.9rem !important;
    }

    .btn-group{
        display: inline !important;
    }

}

.btn-save,.btn-cancel{
    width:100%;
}

.btn-save{
    background-color: #0C2651 !important;
    color:#F5F5F5 !important;
}

.header-fit{
    width:fit-content;
}

