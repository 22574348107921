.profile {
    text-align: left !important;
    padding: 1.5rem;
}

.form {
    display: block;
    margin: 1.5rem;
}
.nav.nav-pills .nav-link.active {
    background-color: #f5f5f5;
}
.image-crop-container {
    width: 100%;
    height: 400px;
    position: relative;
    background-image: linear-gradient(45deg, #808080 25%, transparent 25%),
        linear-gradient(-45deg, #808080 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, #808080 75%),
        linear-gradient(-45deg, transparent 75%, #808080 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
    box-shadow: 0px 0px 5px 1px rgb(218, 218, 218);
}
.settings-image-container {
    position: relative;
    background: #c4c4c4;
    border-radius: 10px;
    width: 450px;
    height: 350px;
    /* left: 625px;
    top: 1372px; */
}
.settings-image-centered {
    width: 150px;
}

.default-settings-image {
    position: absolute;
    transform: scale(4) !important;

    left: 48% !important;
    top: 44% !important;
    color: #808080 !important;
}

.settings-save-button {
    background: black !important;
    /* background: #ffffff; */

    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px; */
}

.slider-container {
    display: flex;
    align-items: center;
    padding: 12px;
}

.slider-label {
    min-width: 85px;
}

.slider {
    width: 60px;
}

.image-preview-container {
    width: 500px;
    position: relative;
    display: flex;
    text-align: left;
    flex: 1;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(45deg, #808080 25%, transparent 25%),
        linear-gradient(-45deg, #808080 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, #808080 75%),
        linear-gradient(-45deg, transparent 75%, #808080 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
    margin-top: 10px;
    box-shadow: 0px 0px 5px 1px rgb(218, 218, 218);
}

.image-preview-container img {
    width: 100%;
}
