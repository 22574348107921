.sign-register-modal-container {
    /* width: 100%; */
}

.sign-register-photo {
    background-color: #0c2651;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 2em;
}
