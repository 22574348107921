.inputPosition {
    margin-top: 0.8rem;
}

.formLength {
    width: 20%;
}
.img-cancel-button {
    position: absolute;
    top: 5px;
    left: 9.5em;
    background-color: white;
    /* right: -15px; */
}

.product-config-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
    margin-top: 1em;
    margin-bottom: 3em;
    padding-bottom: 5em;
}

.config-title {
    font-weight: 500;
    font-size: 2rem;
}

.config-subtitle {
    font-weight: 350;
    font-size: 1.2rem;
}

.config-loading-container {
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.config-loading-icon {
    margin-left: auto;
    margin-right: auto;
    max-width: 50px;
}

.config-continue {
    padding: 0;
    margin: 0;
    letter-spacing: 0.05rem;
    font-weight: 400;
    text-transform: none;
}

.image-crop-container {
    width: 100%;
    height: 400px;
    position: relative;
    background-image: linear-gradient(45deg, #808080 25%, transparent 25%),
        linear-gradient(-45deg, #808080 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, #808080 75%),
        linear-gradient(-45deg, transparent 75%, #808080 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
    box-shadow: 0px 0px 5px 1px rgb(218, 218, 218);
}
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    border-color: #ced4da !important;
    border-radius: 3px;
}
.editor-class {
    border: 1px solid black;
    border-radius: 3px;
    padding: 0.5rem;
}

.addon-create-modal {
    width: 90% !important;
}

.slider-container {
    display: flex;
    align-items: center;
    padding: 12px;
}
.middle-menu-summary {
    margin-left: 20px;
    margin-right: 20px;
}

.delivery-day-config-button {
    border-radius: 30px !important;
    background-color: grey !important;
    max-width: 30px !important;
    max-height: 30px !important;
    min-width: 30px !important;
    min-height: 30px !important;
    margin: 5px !important;
}

.delivery-day-config-button-active {
    border-radius: 30px !important;
    background-color: #0c2651 !important;
    color: white !important;
    max-width: 30px !important;
    max-height: 30px !important;
    min-width: 30px !important;
    min-height: 30px !important;
    margin: 5px !important;
}
.loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 1500ms infinite;
    animation: ellipsis steps(4, end) 1500ms infinite;
    content: "\2026"; /* ascii code for the ellipsis character */
    width: 0px;
}

@keyframes ellipsis {
    to {
        width: 20px;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 20px;
    }
}

.slider-label {
    min-width: 85px;
}

.slider {
    width: 60px;
}

.image-preview-container {
    width: 500px;
    height: 500px;
    position: relative;
    display: flex;
    text-align: left;
    flex: 1;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(45deg, #808080 25%, transparent 25%),
        linear-gradient(-45deg, #808080 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, #808080 75%),
        linear-gradient(-45deg, transparent 75%, #808080 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
    margin-top: 10px;
    box-shadow: 0px 0px 5px 1px rgb(218, 218, 218);
}

.image-preview-container img {
    width: 100%;
}

.preview-images {
    border: 1px solid black;
    border-radius: 10px;
    width: 800px !important;
    height: 370px;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
    font-size: 2.2rem !important;
    /* padding-bottom: -2px !important; */
    /* padding-top: 1em !important; */
}

.css-117w1su-MuiStepIcon-text {
    font-size: 0.65rem !important;
}
.css-zpcwqm-MuiStepConnector-root {
    top: 17px !important;
    left: calc(-50% + 25px) !important;
    right: calc(50% + 25px) !important;
}
.css-z7uhs0-MuiStepConnector-line {
    border-top-width: 2px !important;
}

.form-control {
    border-color: black;
}

.form-select {
    border-color: black !important;
}

.form-check-input {
    border-color: black !important;
}

.discount-setup-input {
    padding: 0em 0.2em;
}

.continue-button {
    color: white !important;
    background-color: #0c2651 !important;
    border-color: #5b5b5b !important;
    border-style: solid !important;
    border-width: thin !important;
    border-radius: 6px !important;
}

.back-button {
    text-transform: none !important;
    margin-right: 1.5em !important;
    background-color: #f5f5f5 !important;
    color: black !important;
    border-color: black !important;
    border-style: solid !important;
    border-radius: 6px;
    border-width: thin !important;
}

.step-container {
    background-color: white;
    width: 80%;
    margin-top: 0.2em;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    padding: 2em 4em;
}

.slide-right {
    -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-right {
    0% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
    }
    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}
@keyframes slide-right {
    0% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
    }
    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}

.MuiInput-underline:after {
    border-bottom: 0 !important;
}

.override-button-label {
    justify-content: space-between !important;
}

div.ReactTags__tags {
    position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
    width: 100%;
    border-radius: 2px;
    border-color: black;
    /* border-radius: 6px 6px 0px 0px;
  border-style: solid;
  border-width: thin; */
    margin-top: 0.2em;
    display: inline-block;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    width: 100%;
    /* border-color: black; */
    border-radius: 6px 6px 0px 0px;
    padding: 1.1em 0.5em;
}
input.ReactTags__tagInputField:focus {
    outline: none;
}

.ReactTags__selected {
    padding: 0.5em;
    border-radius: 0px 0px 6px 6px;
    border-color: black;
    border-top-color: white;
    border-style: solid;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid;
    border-radius: 6px;
    background: #3395ff !important;
    color: white;
    font-size: 0.8rem;
    display: inline-block;
    padding: 0.2em 0.5em;
    margin: 0 5px;
    cursor: move;
    border-radius: 6px;
}
div.ReactTags__selected a.ReactTags__remove {
    color: white;
    margin-left: 15px;
    cursor: pointer;
}

.ReactTags__remove {
    background-color: #3395ff;
    color: white !important;
    border: 0;
    font-size: 1rem;
    padding: 0 0.2em;
    padding-left: 0.5em;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
}
div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
}
div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}
div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}

.form-check-input:checked[type="radio"] {
    background-color: black;
}
.MuiInput-underline:before {
    border: 0 !important;
}
p {
    margin-bottom: 0em;
}

.tag-tooltip {
    color: black;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tag-tooltip-card {
    width: 225px;
    height: 150px;
    background-color: #c4c4c4;
    border-radius: 6px 6px 0px 0px;
    padding: 1em;
}

.tag-tooltip-chip {
    border-radius: 6px;
    background-color: #808080;
}

.img-preview-container {
    padding-top: 1.5em;
    border-color: black;
    border-radius: 6px;
    border-style: solid;
    margin-top: 1em;
}

.img-preview-images {
    width: 150px;
    height: 150px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 0.3em;
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    border-radius: 10px;
}

.form-group.required .control-label:after {
    content: "*";
    color: red;
}

#selected-menu {
    background-color: #3395ff !important;

    margin: 0.6em;
    margin-left: 0.8em;
    border-radius: 6px;
    padding: 0.1em;
}

#selected-menu-text {
    padding: 0.5em;
    color: white;
}

@media only screen and (max-width: 960px) {
    .middle-menu-summary {
        margin-left: 0px;
        margin-right: 0px;
    }
    .preview-images {
        border: 1px solid black;
        border-radius: 10px;
        width: 230px !important;
        height: 600px !important;
    }
    .product-config-container {
        margin-left: auto;
        margin-right: auto;
        max-width: 80%;
        margin-top: 1em;
        margin-bottom: 3em;
    }
    .step-container {
        /* background-color: white; */
        width: 100%;
        padding: 1em;
        /* margin-top: 0.2em;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
       
        padding-left: 2em;
        padding-right: 2em; */
    }
}
