.grid-header{
    font-weight: 500;
}

.avatar-icon{
    background-color: #0C2651 !important;
}

.avatar-profile{
    width:5em !important;
    height:5em !important;
    margin-top: -2em;
    border:3px solid #F5F5F5 !important;
}

.card-col-spacing{
    margin-top:20px;
    padding:10px;
}

.banner{
    background-color: #0C2651;
    height:250px;
    width:100% !important;
    box-shadow: none !important;
    border-radius: 0 !important;
}

.details-name{
    font-size: 1.5em !important;
    font-weight: bold !important;
}

.details-date{
    font-size: 1.2em !important;
}

.col-details{
    margin-left: -25em !important;
}

.col-profile{
    margin-left:3em !important;
}

.profile-edit{
    background-color: #0C2651 !important;
    margin-top: -5em;
    margin-left: 3em;
}

@media only screen and (max-width: 1400px){
    .container-details{
        padding:0 !important;
    }

    .banner{
        padding: 0 !important;
    }
    .col-details{
        margin-left:0.5em !important;
    }
    .col-banner{
        margin:0 !important;
        padding: 0 !important;
    }
    .col-profile{
        margin-left:0 !important;
    }
    .banner{
        height:200px;
    }
}
