.product-detail-container {
    padding: 2em 6em;
    min-height: 100vh;
}

.default-purchase-options{
    background-color: white !important;
    border-color: grey !important;
    color:grey !important;
    border-width: medium;
    font-weight: 500;
    padding: 0.8em 0.5em;
    margin: 0;
}

.purchase-options {
    background-color: white !important;
    border:3px solid red !important;
    color: grey !important;
    padding: 0.8em 0.5em;
    margin: 0;
}

.selected-purchase-options{
    background-color: white !important;
    border-color: #0c2651 !important;
    color: #0c2651 !important;
    padding: 0.8em 0.5em;
    margin:0;
}

.quantity-minus-button {
    border-radius: 6px 0 0 6px !important;
    background-color: white !important;
    color: black;
    border-right: none;
    box-shadow: none;
}
.quantity-minus-button:hover {
    color: black !important;
}

.quantity-input-button {
    border-radius: 0;
    border-right: none;
    border-left: none;
    /* border: 0 1px solid black; */
}

.quantity-plus-button {
    border-radius: 0 6px 6px 0 !important;
    background-color: white !important;
    border-left-style: none;
    border-left-width: 0;
    color: black;
    box-shadow: none;
}
.quantity-plus-button:hover {
    color: black;
}

.details-cancel-button {
    background-color: white !important;
    color: black !important;
}

.back-word {
    color: #3395ff;
    cursor: pointer;
}

@media only screen and (max-width: 991px) {
    .product-detail-container {
        padding: 2em 2em;
    }
}
