.overlay {
    position: fixed; /* Sit on top of the page content */
    /* display: block !important; Hidden by default */
    width: 100% !important; /* Full width (cover the whole page) */
    height: 100% !important; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999; /* Specify a stack order in case you're using a different order for other elements */
}

.sign-in-button {
    background-color: #0c2651 !important;
}

.credential-bar {
    margin: 3em 15em 0 10em;
}

@media only screen and (max-width: 991px) {
    .credential-bar {
        margin: 1em 5em 0px 5em;
    }
    .credential-logo {
        justify-content: center;
    }
}
