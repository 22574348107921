.accordion-style {
    border: 1px solid;
    padding: 10px;
    /* box-shadow: 5px 5px #e1e1e1; */
    border-radius: 6px !important;
    filter: drop-shadow(4px 4px 2rem #e1e1e1);
}

.accordion-button {
    padding: 0em;
    background-color: white !important;
    color: black !important;
    align-self: center !important;
}
.accordion-button::selection {
    color: none;
    background: none;
}
.accordion-button::after {
    display: none;
}
.number-circle {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 4px;
    color: white;
    text-align: center;
}
.close-delivery-window-button {
    background-color: grey !important ;
    border-color: grey !important;
}
.payment-method-selection {
    background-color: white !important;
    color: black;
}

.selected-payment-method-selection {
    background-color: #0c265125 !important;
    color: black;
}

.react-calendar__navigation__label {
    border: 1px solid black !important;
    padding: 0.3em;
    border-radius: 6px;
    background-color: #0c2651 !important;
    margin: 0px 1em;
    color: white;
    /* width: 100px !important; */
}
.react-calendar__navigation__arrow {
    background-color: #0c2651 !important;
    color: white;
}
.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
    display: none;
}

.react-calendar__navigation__next-button {
    margin-left: 10px !important;
}

.react-calendar__navigation__prev-button {
    margin-right: 10px !important;
}

.react-calendar__month-view__days__day--weekend {
    color: #17a1fa !important;
}
/* react-calendar__navigation__next-button */
/* .react-calendar__month-view__weekdays__weekday {
    color: #17a1fa !important;
} */
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    border-color: black !important;
    border-width: 1px !important;
}
.react-datepicker__input-container input {
    padding: 6px !important;
}
.MuiMonthPicker-root {
    background-color: black;
}
.tooltip-inner {
    max-width: fit-content;
}
[aria-label="Saturday"],
[aria-label="Sunday"] {
    color: #17a1fa !important;
}
.picker-overflow {
    overflow: visible !important;
}

abbr {
    text-decoration: none !important;
}

.react-calendar__tile--now {
    background: none !important;
    color: red !important;
}

@media only screen and (max-width: 991px) {
    .number-circle {
        align-self: center;
    }

    .mobile-text {
        font-size: 1.2rem !important;
    }
}
