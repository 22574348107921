.breadcrumb-bar {
    background-color: #ffffff;
    position: fixed;
    top: 5;
    width: -webkit-fill-available;
    z-index: 1;
}

.toggle-sign-out {
    box-shadow: none !important;
    font-size: 0.8rem !important;
    margin: 0 !important;
    margin-right: 0.4rem !important;
}

.sign-in-register:hover {
    cursor: pointer;
}

.sign-in-register {
    text-transform: none;
}

.breadcrumb-details {
    margin-left: 4.8em;
}

.breadcrumb-design-expanded {
    margin-left: 16em;
    padding-left: 2.5em;
    padding-right: 2.5em;
    min-height: 4em;
}

@media only screen and (max-width: 991px) {
    .breadcrumb-bar {
        width: "-webkit-fill-available";
        z-index: 1;
    }
    .breadcrumb-design {
        padding-left: 0;
        padding-right: 0;
    }
    .breadcrumb-details {
        margin-left: 2.5em;
    }
    .breadcrumb-design-expanded {
        margin-left: 0;
        padding-right: 0;
    }
}
