.list-view-card {
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  margin-top: 2em;
  height: 15em;
  width: 100%;
  background-color: white;
  padding-left: 0px;
}

.list-view-card-image {
  max-width: 100%;
  border-radius: 6px 0px 0px 6px;
}

.list-view-card-content {
  padding: 2em;
  padding-top: 2em;
}

.list-view-card-chip {
  border-radius: 4px !important;
  margin-right: 1em !important;
}

.card-chip-text {
  font-weight: 700;
}

.card-title {
  padding-top: 0.5em;
  font-weight: 700;
}

.card-price-tag {
  color: #5b5b5b;
}

@media screen and (max-width: 991px) {
  .list-view-card-image {
    border-radius: 6px;
    margin: 1.2em;
  }

  .list-view-card-content {
    padding: 0;
  }

  .card-description {
    display: none;
  }
}
