.tab-active {
    color: #fff !important;
    background-color: black !important;
    border-color: black !important;
}
.tab-deactive {
    color: black !important;
    background-color: white !important;
    border-color: white !important;
}

h3 {
    font-weight: bolder !important;
    font-size: 2rem !important;
}

h4 {
    font-size: 1.5rem !important;
    font-weight: 400 !important;
}

/* h6{
    color:#F5F5F5 !important;
    font-size: 1.6rem !important;
} */

.header-link {
    cursor: pointer;
    padding-left: 5px;
    color: #3395ff;
}

.btn-login,
.btn-signup,
.btn-reset {
    background-color: #0c2651;
    color: white;
    width: 100%;
    font-size: 25px;
    padding: 7px;
    margin: 0px;
    text-transform: capitalize;
}

.btn-login:hover,
.btn-signup:hover,
.btn-reset:hover {
    color: white;
}

.grid-img {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 50px;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
}

.header-top {
    color: white;
    font-weight: bold;
}

#inputGroupPrepend {
    background-color: transparent;

    display: flex;
}
