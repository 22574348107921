.title-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding-top: 1em;
}

.homepage-wrapper {
    min-width: 100%;
    padding-right: 0 !important;
}

.homepage-display {
    min-width: 100%;
}

.homepage-title {
    font-weight: 700 !important;
    display: flex;
    align-items: center;
}

.homepage-text {
    text-align: left;
}

.view-button {
    color: white;
    text-transform: none;
    border-radius: 4px;
    margin-right: 0;
    display: block;
}

.button-display {
    display: flex;
    flex-direction: row;
}

.layout-button-icon {
    margin-right: 0.5em;
}

@media only screen and (max-width: 991px) {
    .homepage-display {
        padding-left: 1em;
    }
    .layout-button-icon {
        margin-right: 0;
    }
    .title-bar {
        display: block;

        padding-top: 1em;
    }
    .hide-text-mobile {
        display: none;
    }
    .view-button {
        margin-left: 0;
    }
}
