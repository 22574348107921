/* import app.css */
@import "../../App.css";

/* Position and sizing of burger button */
/* .bm-burger-button {
    position: fixed;
    width: 24px;
    left: 40px;
    top: 23px;
    background: red;
    color: white;
    border-radius: 4px;
} */

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
    height: 5% !important;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #808080;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
    top: 40px !important;
}

/* Color/shape of close button cross */
/* .bm-cross {
    background: #0c2651;
    color: white;
    border-radius: 4px;
} */

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
    width: 16rem !important;
    /* transform: translate(0, -50%); */
}

/* General sidebar styles */
.bm-menu {
    background: #ffffff;
    padding: 0.5em 1.1em 0;
    box-shadow: 2px 5px 25px -4px rgba(0, 0, 0, 0.2);
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: black;
    padding: 0.3em 0.8em 0;
}

/* Individual item */
.bm-item {
    display: inline-block;
    color: #808080;
    margin-bottom: 6px;
    text-align: left;
    text-decoration: none;
    /* transition: color 0.2s; */
    font-weight: 300;
    /* margin-right: 2em; */
    font-size: 12px;
}

/* .bm-item:hover {
    color: #0c2651;
    font-weight: 400;
} */
.not-btm-item {
    display: block;
    color: #808080;

    /* color: inherit; */
    padding-top: 0.7em;
}

.not-btm-item:hover {
    color: #0c2651;
    font-weight: 400;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    background-color: white !important ;
    width: 0 !important;
    height: 0 !important;
}

.menu-item {
    padding: 0.9em 0 0;
}

.dropdown-container {
    display: none;
    padding-left: 3.8em;
    padding-top: 0.5em;
}

.compliance-text {
    font-size: 0.8rem;
    margin-top: auto;
    margin-bottom: auto;
}
.slide-bottom {
    animation: rotateY 300ms ease-in-out forwards;
    transform-origin: top center;
    -webkit-animation: rotateY 300ms ease-in-out forwards;
}

.compliance-button:hover {
    color: white;
}

.display-profile:hover {
    /* texxt underline */
    text-decoration: underline;
}

.icon-margin {
    margin-right: 0.3em;
}

.dropdown-menu {
    cursor: pointer;
}

@media only screen and (max-width: 991px) {
    .bm-burger-button {
        left: 20px;
        top: 15px;
    }

    .bm-overlay {
        width: 100% !important ;
        height: 100% !important ;
        background: rgba(0, 0, 0, 0.3) none repeat scroll 0% 0% !important ;
    }
}
