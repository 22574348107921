.table-header {
    background-color: #0c2651;
    color: white;
}

.table-body {
    background-color: white;
}

.rows-per-page {
    width: 10%;
    background-color: black;
    border-radius: 5px;
    border: 1px solid white !important;
}

.footer-div {
    display: flex;
    justify-content: center;
    align-items: center;
}
.css-1yy0dv2 {
    display: block !important;
}

.action-button {
    z-index: 21474836499;
}
.Toastify__toast--success {
    background-color: #59c19f !important;
}

.Toastify__toast {
    padding: 20px !important;
    border-radius: 6px !important;
}
.new-create {
    /* background: black;
	border-radius: 50%;
	margin: 10px;
	height: 20px;
	width: 20px;

	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1); */
    background-color: #222;
    animation-name: color;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}
.css-1iw3u2h-MuiDataGrid-root .MuiDataGrid-sortIcon,
.css-1iw3u2h-MuiDataGrid-root .MuiDataGrid-filterIcon {
    color: white;
}
.modal-dialog .modal-content {
    border-radius: 10px !important;
}

.cancel-export {
    background-color: white !important;
    color: #0c2651 !important;
}

.form-check-input:checked {
    background-color: #0c2651 !important;
}

.css-1iw3u2h-MuiDataGrid-root .MuiDataGrid-cell--textLeft {
    justify-content: center !important;
}

.css-1iw3u2h-MuiDataGrid-root .MuiDataGrid-cell--textRight {
    justify-content: center !important;
}

.css-rtrcn9-MuiTablePagination-root {
    color: white !important;
}

.MuiIconButton-root.Mui-disabled {
    color: white !important;
}

.css-17jjc08-MuiDataGrid-footerContainer {
    border-radius: 0 0 6px 6px;
}

/* .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root {
    color: white !important;
} */

@keyframes color {
    0% {
        background-color: #7aaaf2fc;
    }
    50% {
        background-color: #d4e5ff;
    }
    100% {
        background-color: #7aaaf2fc;
    }
}

@media only screen and (max-width: 991px) {
    .hide-button-text-mobile {
        display: none;
    }
}
