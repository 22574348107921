.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: 0 !important;
}

.MuiOutlinedInput-root {
    border: 1px solid black;
}

.MuiOutlinedInput-input {
    padding: 0.8em 0.5em !important;
}

.MuiIconButton-root {
    padding-right: 0.5em;
}
